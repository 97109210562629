import { Notifier } from '@airbrake/browser';
import { PUBLIC_NODE_ENV } from '$env/static/public';

const airbrake = new Notifier({
  environment: PUBLIC_NODE_ENV,
  projectId: 515505,
  projectKey: 'ac3ffcd7df87b7f7118d05c0b31c8a54',
});

airbrake.addFilter((notice) => {
  const newNotice = notice;
  newNotice.context.hooks = 'client';
  if (newNotice.context.environment === 'development') return null;

  return newNotice;
});

export const handleError = ({ error, event }) => {
  const errorId = window.crypto.randomUUID();
  airbrake.notify({
    error,
    params: { errorId, event },
  });
  return {
    message: error as any,
    errorId,
  };
};
